@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.dashboard {
  padding-top: 50px;
  padding-bottom: 25px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &__wrap {
    @extend %inner-wrap;
    padding-top: 0;
  }

  @media (max-width: 680px) {
    padding-top: 0;
    margin-top: 50px;
  }
}

.dashboard--analytics-overview-section {
  display: block;
  margin-top: 75px;
  max-width: 1250px;
  width: 100%;
  background-color: white;
  min-height: 250px;
  margin: auto;
}

.shift-activity--container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  align-content: stretch;
  background-color: transparent;
  min-height: 0px;
  max-width: 1250px;
  width: 100%;
  margin: auto;
  margin-top: 16px;
}

@media (max-width: 1240px) {
  .shift-activity--container {
    justify-content: space-around;
  }

  .requests-feed:last-child {
    // margin-top: 20px;
  }
}
