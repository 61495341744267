@import 'stylesheets/_vars.scss';

.mobile-pin-input {
  margin: 70px auto 0 auto;
  text-align: center;

  @media (max-width: $post-shift-xs) {
    margin-top: 55px;
  }

  & > input {
    text-align: center;
    -webkit-appearance: none;
    border-radius: 0;
    width: 60px;
    line-height: 60px;
    padding: 0;
    font-size: 56px;
    border: 2px solid $border-color;

    @media (max-width: $post-shift-xs) {
      width: 50px;
      font-size: 46px;
    }

    &:focus {
      outline: none;
      border-color: $primary-blue;
    }

    &::selection {
      background-color: transparent;
    }
  }

  & > input:not(:last-child) {
    margin-right: 15px;
  }
}
