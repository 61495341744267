@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

$textColor: #808080;
$lightBorderColor: #DEDEDE;
$fontWeight: 200;

.select-dropdown {
  display: inline-block;
  position: relative;

  &.open {
    z-index: 2;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }

  @media (max-width: $shifts-sm) {
    text-align: center;
    min-width: 250px;
  }
}

.select-dropdown--mask {
  display: inline-block;
  border: none;
  font-size: 14px;
  font-weight: 300;
  min-height: 17px;

  padding: 13px 15px 0;

  text-decoration: none;
  letter-spacing: 0.8px;
  text-transform: uppercase;

  border-radius: 3px;
  cursor: pointer;
  color: $textColor;
  background-color: transparent;

  &.readonly {
    cursor: default;

    &.select-dropdown--mask:hover {
      box-shadow: none;
    }
  }
}

.select-dropdown--mask:hover {
  .select-dropdown--text {
    color: $primary-red;
  }
}

.select-dropdown--subtext-container {
  @media (max-width: $shifts-sm) {
    display: none;
  }
}

.select-dropdown--subtext-description {
  font-size: 12px;
  text-transform: uppercase;
  color: $textColor;
  font-weight: $fontWeight;
}

.select-dropdown--arrow,
.select-dropdown--text {
  height: 17px;
  line-height: 17px;
}

.select-dropdown--arrow {
  float: right;
  margin-left: 5px;
}

.select-dropdown-arrow > svg {
  vertical-align: middle;
}

.select-dropdown--text {
  float: left;
}

.select-dropdown > .select-dropdown--mask > svg {
  transition: transform 250 ease;
}

.select-dropdown--list {
  display: inline-block;
  // position: absolute;
  top: 46px;
  left: 0;
  right: 0;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  max-height: 400px;
  width: 260px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.select-dropdown--list-item {
  @extend %button-reset;
  color: $textColor;
  font-weight: $fontWeight;
  margin-top: -1px;
  font-size: 14px;
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: white;
  cursor: pointer;
  border-top-right-radius: 1px;
  border: 1px solid $border-color;
}

.select-dropdown--list-item:hover {
  background-color: $inputBorderFocusColor;
  color: white;
  font-weight: 400;
}

.select-dropdown--list-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.select-dropdown--list-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.groups {
  .select-dropdown--mask {
    font-family: "SF Pro Display", sans-serif;
    letter-spacing: normal;
    font-weight: 300;
    border-radius: 0;
    text-transform: none;
    border: 1px solid $lightBorderColor;
    padding: 0.4rem;
    max-height: 32px;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .select-dropdown--text {
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .select-dropdown--mask:hover {
    .select-dropdown--text {
      color: $textColor;
    }
  }

  .select-dropdown--arrow {
    float: right;
  }

  .select-dropdown--arrow {
    float: right;
    margin-left: 5px;
  }

  .icon .downArrow {
    float: right;
  }

  .select-dropdown--list {
    position: absolute;
    top: 32px;
    font-weight: 300;
    min-width: 200px;
    max-width: 260px;
    max-height: 200px;
    right: 0;
    left: 0;
    width: min-content;
    box-shadow: none;
  }

  .select-dropdown--list-item {
    padding: 0.5rem;
    text-align: left;

    &:hover {
      font-weight: 300;
    }
  }
}
