@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.summary {
  

  &__wrap {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    padding-top: 0;
    background-color: white;
    border-bottom: 1px solid rgba(208,208,212,255);
    //border: 1px solid blue;

    @media (max-width: 880px) {
      padding-top: 0;
      margin-top: 50px;
    }
  }

  &__param-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    margin-top: 0;
    margin-left: 20px;
    //border: 1px solid red;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__param-name {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  &__param-value {
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
  }
  
}