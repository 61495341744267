@import 'stylesheets/_vars.scss';

.comparison-counter {
  display: inline-block;
  min-width: 250px;
  min-height: 200px;
  vertical-align: top;
}

.comparison-counter--main-number {
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 56px;
}

.comparison-counter--main-number-description {
  color: $subtextColor;
  font-weight: 600;
  text-align: center;
}

.comparison-counter--comparison-number-container {
  margin-top: 60px;
  margin-left: 5px;
  text-align: center;
}
.comparison-counter--comparison-number {
  font-size: 20px;
  font-weight: 700;
  margin-right: 5px;
}

.comparison-counter--comparison-number-description {
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  color: $subtextColor;
  font-weight: 600;
}
