@import 'stylesheets/vars';

$textColor: #808080;

.radio-button {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding-top: 5px;

  &__btn {
    margin-right: 0.5rem;
    position: relative;

    &__circle {
      width: 15px;
      height: 15px;
      background-color: white;
      background-size: 15px;
      border: 1px solid $grey-light;
      border-radius: 100%;

      &--active {
        background-color: $primary-blue;
        border-color: $primary-blue;
      }
    }

    &__check {
      display: none;
      position: absolute;
      top: 4px;
      left: 3px;
      width: 9px;
      height: 7px;
      background-image: url('../../assets/check.svg');
      background-size: 9px 7px;

      &--active {
        display: block;
      }
    }
  }

  &__label{
    font-family: 'SF Pro Display', sans-serif;
    //color: $textColor;
    color: black;
    font-weight: 500;
    font-size: 14px;
    min-height: 15px;
    margin-bottom: 0px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 100%;
    //border: 1px solid red;
  }

  &__label1 {
    font-family: 'SF Pro Display', sans-serif;
    color: $textColor;
    font-weight: 300;
    font-size: 13px;
    max-height: 17px;
    margin-left: 24px;
    display: block;
    line-height: 100%;
    //border: 1px solid blue;
  }
}
