@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.employee-page-header {
  

  &__top-wrap {
    //@extend %inner-wrap;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    padding-top: 0px;
    background-color: white;
    //border-bottom: 1px solid rgba(208,208,212,255);
    //border: 1px solid blue;

    @media (max-width: 880px) {
      padding-top: 0;
      margin-top: 50px;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width:100%;
  }

  &__row-1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    //border: 1px solid red;
  }

  &__location-dropdown-wrap {
    padding-right: 10px;
    //border: 1px solid red;
  }

  &__dropdown-item-wrap {
    //width: 200px;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      background-color: rgba(242,242,247,255);
      //border: 1px solid rgba(0,135,255,255);
    }
    
  }

  &__dropdown-text {
    margin-top: 6px;
    margin-left: 20px;
    //width: 180px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 18px;
    font-weight: 400;
    //border: 1px solid blue;
  }

  &__action-dropdown-text {
    margin-top: 6px;
    margin-left: 20px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 12px;
    font-weight: 400;
    //border: 1px solid red;
  }

  &__arrow-button-down {
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 10px;
    background-image: url("/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //border: 1px solid red;

    &:focus {
      outline: none;
    }
  }

  &__arrow-button-up {
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 10px;
    background-image: url("/icons/up-arrow.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //border: 1px solid red;
    
    &:focus {
      outline: none;
    }
  }

  &__results-text {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 400;
    //border: 1px solid green;
  }

  &__row-2 {
    width: 100%;
    height: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    //border: 1px solid red;
  }
  &__search-box {
    width: 25%;
    height: 38px;
    margin-left: 15px;
    padding-left: 60px;
    border: 1px solid rgba(115,124,132,255);
    border-radius: 7px;
    background-image: url("../../assets/search-icon.svg");
    background-repeat: no-repeat;
    background-position: 20px 10px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 15px;
    font-weight: 600;
  }

  &__category {
    display: flex;
    flex-direction: row;
    height: 38px;
    width: 200px;
    margin-left: 15px;
    border: 1px solid rgba(115,124,132,255);
    border-radius: 19px;
    background-image: url("../../assets/close-icon.svg");
    background-repeat: no-repeat;
    background-position: 175px 10px;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    padding-top: 15px;
    padding-left: 10px;
    align-items: center;
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__category-text {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    //border: 1px solid blue;
  }

  &__item-name {
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    max-width: 75px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    //border: 1px solid red;
  }

  &__add-filters {
    height: 38px;
    width: 80px;
    margin-left: 15px;
    border: 1px solid rgba(115,124,132,255);
    border-radius: 7px;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__hide-filters {
    height: 18px;
    margin-left: 15px;
    margin-top: 10px;
    border-bottom: 1px solid rgba(115,124,132,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__clear-all {
    height: 18px;
    margin-left: 15px;
    margin-top: 10px;
    border-bottom: 1px solid rgba(115,124,132,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__sort-drop-down {
    position: absolute;
    top: 0px;
    right: 130px;
    min-width: 180px;
    height: 38px;
    padding-top: 7px;
    padding-left: 15px;
    padding-right: 10px;
    border-radius: 6px;
    border: 1px solid rgba(115,124,132,255);
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
  }
  &__sort-item-text {
    margin-left: 10px;
    margin-top: 1px;
    padding-top: 0;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 15px;
    font-weight: 600;
    //border: 1px solid red;
  }
  &__sort-arrow-button-down {
    width: 15px;
    height: 15px;
    margin-left: 40px;
    margin-top: 4px;
    background-image: url("/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //border: 1px solid red;

    &:focus {
      outline: none;
    }
  }
  &__sort-arrow-button-up {
    width: 15px;
    height: 15px;
    margin-left: 40px;
    margin-top: 4px;
    background-image: url("/icons/up-arrow.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //border: 1px solid red;

    &:focus {
      outline: none;
    }
  }
  &__sort-order-descending {
    position: absolute;
    top: 0px;
    right: 75px;
    width: 38px;
    height: 38px;
    border-radius: 6px;
    border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/descending-arrow-up.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }
  &__sort-order-ascending {
    position: absolute;
    top: 0px;
    right: 75px;
    width: 38px;
    height: 38px;
    border-radius: 6px;
    border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/Ascending-Arrow-Down.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }
  &__refresh-button {
    position: absolute;
    top: 0px;
    right: 20px;
    width: 38px;
    height: 38px;
    border-radius: 6px;
    border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/refresh-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__button-group-1-wrap {
    margin-left: 15px;
    height: 34px;
    //border: 1px solid red;
  }

  &__approve-users {
    height: 34px;
    width: 90px;
    //margin-left: 15px;
    border-radius: 7px;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: white;
    background-color: rgba(0,222,0,255);
    outline: none;

    &:hover {
      cursor: pointer;
      outline: none;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__deny-users {
    height: 34px;
    width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(164,168,172,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //color: white;
    background-color: rgba(242,242,247,255);
    outline: none;

    &:hover {
      cursor: pointer;
      outline: none;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__button-group-2-wrap {
    margin-left: 55px;
    padding-top: 0;
    height: 34px;
    //border: 1px solid red;
  }

  &__add-users {
    height: 34px;
    width: 90px;
    //margin-left: 55px;
    margin-top: 0;
    border-radius: 7px;
    border: 1px solid rgba(164,168,172,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__remove-users {
    height: 34px;
    width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(164,168,172,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //color: white;
    //background-color: rgba(242,242,247,255);

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }

  &__open-more {
    width: 34px;
    height: 34px;
    margin-left: 10px;
    border-radius: 6px;
    border: 1px solid rgba(164,168,172,255);
    background-image: url("../../assets/ellipses-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //border: 1px solid red;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border: 1px solid rgba(0,135,255,255);
    }

    &:focus {
      outline: none;
    }
  }
}

.confirm {
  
  &__label-wrap {
    display: flex;
    flex-direction: column;
    //border: 1px solid red;
  }

  &__title {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
    //border: 1px solid red;
  }

  &__text-wrap {
    display: flex;
    flex-direction: row;
    height: 26px;
    padding-left: 0px;
    width: fit-content;
    //border: 1px solid blue;

    &:first-child {
      margin-bottom: 3px;
    }
   
    &:hover {
      //cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__img {
    width: 14px;
    heith: 14px;
    margin-left: 0px;
    margin-right: 10px;
  }

  &__text {
    margin-top: 3px;
    height: 20px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    overflow: visible;
    white-space: nowrap;
    //border: 1px solid blue;
  }

  &__text-bold {
    margin-top: 3px;
    margin-left: 5px;
    margin-right: 5px;
    height: 20px;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    //border: 1px solid blue;
  }

  &__button-wrap {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
    margin-left: 150px;
    margin-bottom: 20px;
    //border: 1px solid red;
  }

  &__button-wrap-create {
    display: flex;
    flex-direction: row;
    margin-top: 22px;
    margin-left: 142px;
    //border: 1px solid red;
  }

  &__button {
    height: 34px;
    width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //background-color: rgba(242,242,247,255);
    color: white;
    background-color: rgba(11,132,255,255);
    border: 1px solid rgba(11,132,255,255);

    &:hover {
      cursor: pointer;
      color: white;
      background-color: rgba(11,132,255,255);
      border: 1px solid rgba(11,132,255,255);
    }
  }

  &__button-disabled {
    height: 34px;
    width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    //border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #6D7278;
    background-color: rgba(242,242,247,255);

    &:hover {
      //cursor: pointer;
      //color: white;
      //background-color: rgba(11,132,255,255);
      //border: 1px solid rgba(11,132,255,255);
    }
  }

  &__button-cancel {
    height: 34px;
    width: 90px;
    margin-left: 10px;
    border-radius: 7px;
    border: 1px solid rgba(153,156,161,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //color: white;
    background-color: rgba(242,242,247,255);

    &:hover {
      cursor: pointer;
      //color: white;
      //background-color: rgba(11,132,255,255);
      //border: 1px solid rgba(11,132,255,255);
    }
  }

  &__input-box {
    width: 340px;
    height: 34px;
    margin-right: 30px;
    padding-left: 10px;
    border-radius: 5px;
    border: 1px solid rgba(153,156,161,255);

    &:hover {
      border: 1px solid rgba(11,132,255,255);
    }
  }
}

.confirm-operation {

  &__title {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
    //border: 1px solid red;
  }

  &__group-name-wrap {
    display: flex;
    flex-direction: column;
    height: 50px;
    border-radius: 7px;
    padding-top: 3px;
    padding-left: 8px;
    //margin-right: 30px;
    margin-bottom: 10px;
    border: 1px solid rgba(161,161,163,255);
    //border: 1px solid red;
  }

  &__group-description-wrap {
    display: flex;
    flex-direction: column;
    height: 120px;
    border-radius: 7px;
    padding-top: 3px;
    padding-left: 8px;
    //margin-right: 30px;
    margin-bottom: 10px;
    border: 1px solid rgba(161,161,163,255);
  }

   &__caption {
    //height: 20px;
    margin-bottom: 0px;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    color: rgba(172,175,178,255);
    //border: 1px solid blue;
  }

  &__name {
    //height: 20px;
    margin-right: 8px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 300;
    text-align: left;
    outline: none;
    border: none;
    //border: 1px solid blue;

    &:hover {
      //border: 1px solid blue;
    }
  }

   &__description {
    //height: 20px;
    margin-right: 8px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 300;
    text-align: left;
    outline: none;
    border: none;
    resize: none;
    height: 80%;
    //border: 1px solid red;

    &:hover {
      //border: 1px solid blue;
    }
  }

  &__group-drop-down {
    position: relative;
    //width: 340px;
    height: 38px;
    padding-left: 15px;
    border-radius: 6px;
    border: 1px solid rgba(115,124,132,255);
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 10px;
    //border: 1px solid red;
  }

  &__arrow-button-down {
    position: absolute;
    right: 10px;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 10px;
    background-image: url("/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //border: 1px solid red;
    &:focus {
      outline: none;
    }
  }

  &__arrow-button-up {
    position: absolute;
    right: 10px;
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 10px;
    background-image: url("/icons/up-arrow.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //border: 1px solid red;
    &:focus {
      outline: none;
    }
  }

  &__drop-down-placeholder {
    margin-top: 9px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 13px;
    font-weight: 600;
    //text-align: left;
    color: rgba(172,175,178,255);
    //border: 1px solid blue;
  }

  &__group-name {
    margin-top: 9px;
    padding-left: 15px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 13px;
    font-weight: 600;
    //text-align: left;
    color: rgba(112,112,113,255); //rgba(172,175,178,255);
    //border: 1px solid red;
  }
  &__dot-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    //border: 1px solid red;
  }
  &__dot {
    display: inline-block;
    height: 2px;
    width: 2px;
    //margin-top: 0px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
    border: 2px solid black;
  }
  &__text {
    margin-top: 7px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 400;
    //border: 1px solid red;
  }
  
}
