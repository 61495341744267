.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkbox > span {
    color: #34495E;
    padding: 0.5rem 0.25rem;
}

.checkbox > input {
    height: 17px;
    width: 17px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid rgb(61,174,255);
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: rgb(255,255,255);
    cursor: pointer;
  }

.checkbox > input:checked {
    border: 1px solid rgb(61,174,255);
    background-color: rgb(61,174,255);
}

.checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    align-content: center;
    /*color: red;*/
    color: rgb(255,255,255);
    font-weight: 600;
    position: absolute;
    left: 1px;
    top: -3px;
}

.checkbox > input:active {
    border: 2px solid rgb(61,174,255);
}