$fontFamily: 'Trueno', 'SF Pro Text', 'San Francisco', 'Nunito', 'Open-Sans',
  sans-serif;
$primaryColor: #fc5858;
$darkFontColor: #424242;
$lightFontColor: #90959a;
$headerColor: #515457;
$fontColor: #6a7177;
$linkColor: #3eaeff;
$approveColor: #21c2fd;
$bodyBackgroundColor: #eaecee73;
$pendingColor: #f5c623;
$approvedColor: #26ce93;
$deniedColor: #fc5858;
$lightGreyColor: #d8d8d8;
$subtextColor: #979797;
$neutralGreyColor: #ebedee;
$semiDarkGreyColor: #e0e4e6;
$darkGreyColor: #5d5d5d;
$locationHeaderColor: #6e6e6e;
$locationColorBackground: #dfe6ed;
$inputBorderFocusColor: #979797;
$approveButtonBackgroundColor: #21c2fd;
$denyButtonBorderAndTextColor: #111111;
$buttonBoxShadow: 0 2px 4px rgba(0, 0, 0, 0.5);
$buttonBorderRadius: 4px;
$textDescriptionColor: #727272;
$smallFontSize: 12px;
$largeFontSize: 64px;
$thumbnailSize: 40px;
$partialOpacity: 0.57;
$ultraLightFontWeight: 100;
$lightFontWeight: 300;
$regularFontWeight: 500;
$lightBoldFontWeight: 600;
$boldFontWeight: 700;
$veryBoldFontWeight: 800;
$ultraBoldFontWeight: 900;
$loadingGifUrl: '/loader.gif';

$fontFamily: 'Trueno', 'SF Pro Text', 'San Francisco', 'Nunito', 'Open-Sans',
  sans-serif;

$primary-blue: #3eaeff;
$primary-red: #fc5858;
$primary-yellow: #f5c623;
$primary-green: #26ce93;

// greyscale
$grey-dark-very: lighten(black, 17%);
$grey-dark: lighten(black, 33%);
$grey-medium: lighten(black, 50%);
$grey-light: lighten(black, 67%);
$grey-light-very: lighten(black, 83%);

$button-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
$button-border-radius: 4px;
$container-border-radius: 6px;
$container-box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.5);

$small-font-size: 12px;
$large-font-size: 64px;
$thumbnail-size: 40px;

$partial-opacity: 0.57;

$loading-gif-url: '/loader.gif';

// --------------

// Colors
$border-color: #d1d1d1;
$label-color: #acacac;
$member-color: #636363;
$button-inactive-color: #e8e8e8;
$comment-color: #8d8d8d;
$app-bg-color: #e9ecee;

// Misc
$border: 1px solid $border-color;
$sf-pro: 'SF Pro Display', sans-serif;
$radius: 6px;

// BreakPoints
$ipad-portrait: 1024px;
$shifts-md: 884px;
$post-shift-md: 748px;
$shifts-sm: 680px;
$date-time-sm: 586px;
$post-shift-xs: 476px;
// Height BreakPoints
$modal-height-break: 867px;
$iphone-se: 375px;
$iphone-xr: 414px;
$iphone-12-pro: 390px;


