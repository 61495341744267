@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.login {
  @extend %route-wrap;
  font-family: $fontFamily;
  display: block;
  background-color: white;

  input[type='password'] {
    margin-top: 30px;
    @include input-placeholder('/icons/lock-icon.svg');
  }

  input[name='email'] {
    @include input-placeholder('/icons/envelope-icon.svg', 40px, 16px, 2px);
  }

  &__wrap {
    @extend %inner-wrap;
  }
}

.login__header {
  // margin-top: 2rem;
  text-align: center;
  padding-left: 0;
  font-size: 48px;
  margin-bottom: 75px;

  @media (max-width: $post-shift-xs) {
    font-size: 2rem;
  }
}

.login__form-group {
  & input,
  & .form-control {
    padding: 10px 0 5px 0;
    // min-width: 320px;
    width: 100%;
    font-size: 18px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid $grey-light;
    transition: border-bottom ease 250ms;

    &:focus {
      outline: none;
      border-bottom: 2px solid $primary-blue;
    }
  }

  & .intl-tel-input {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
  }
}

.login__form-action {
  margin-top: 25px;
  text-align: center;

  button {
    background-color: transparent;
  }
}

.login__footer {
  text-align: center;
}

.login__link {
  @extend %button-reset;
  margin-left: 5px;
  color: $primary-blue;

  &:hover {
    text-decoration: none;
    color: black;
  }
}

.login__input-phone {
  display: none;
}
