@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

$padLeft: 20px;

.schedule-table {
  z-index: 1;
  //background-color: $neutralGreyColor;
  width: 100%;
  margin-bottom: 50px;
  border-collapse: collapse; 

  &.loading {
    z-index: -1;
  }

  &__wrap {
    position: relative;
    width: 100%;
    //padding-left: 20px;
    //border: 1px solid blue;
  }
  &__header-title {
    padding-left: $padLeft;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 600;
    color: rgba(9,9,17,255);
    //border: 1px solid green;
  }

  &__headers {
    text-align: left;
    height: 50px; //makes space between header and body
    border-bottom: 1px solid rgba(208,208,212,255);
    //border: 1px solid red;
  }

  &__body {
    //margin-top: 120px;
    //border: 1px solid blue;
  }
}

.schedule-row {

  &__row {
    margin-left: 20px;
    border-bottom: 1px solid rgba(208,208,212,255);
    //border: 1px solid red;
  }

  &__file-name-cell {
    padding-left: $padLeft;
    //border: 1px solid red;
  }

  &__owner-cell {
    padding-left: $padLeft;
    //border: 1px solid red;
  }

  &__status-cell {
    padding-left: $padLeft;
    //border: 1px solid red;
  }

  &__total-cell {
    padding-left: $padLeft;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 600;
    color: black;
    //border: 1px solid red;
  }


  &__succeeded-cell {
    padding-left: $padLeft;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 800;
    color: rgba(0,203,74,255);
    //border: 1px solid red;
  }

  &__failed-cell {
    padding-left: $padLeft;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 800;
    color: rgba(255,46,62,255);
    //border: 1px solid red;
  }

  &__button-more {
    width: 20px;
    height: 20px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/ellipses-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border: 1px solid rgba(0,135,255,255);
    }
  }
}
