@import 'stylesheets/_vars.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $headerColor;
  padding-left: 15px;
}
