@import 'stylesheets/_vars.scss';

.shyft-table--cell {
  height: 60px;
  font-size: 14px;
  color: $headerColor;
  font-weight: 600;
  letter-spacing: 0.6px;
  padding-left: 20px;
  // max-width: 200px;
}

.shyft-table--text-block {
  vertical-align: middle;
  display: inline-block;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shyft-table--text-block-skills{
  vertical-align: middle;
  display: inline-block;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid red;
}

.user-cell {
  padding-left: 20px;
}

.user-cell--avatar {
  display: inline-block;
  height: 35px;
  vertical-align: middle;
  margin-right: 10px;

  & img {
    border-radius: 50%;
  }
}

.user-cell--main-text {
  margin: 0;
  line-height: 60px;
  vertical-align: middle;
  display: inline-block;
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shyft-table-cell--no-data {
  height: 1px;
  border-top: 1px solid $lightFontColor;
  margin-left: 15px;
  width: 35%;
}
