@import 'stylesheets/_vars.scss';

.applicant {
	margin-top: 20px;
	margin-left: 20px;
	width: 100%;
	//border: 1px solid blue;

	&__user-cell {
	 	width: 39.7%;
	 	text-align: left;
	  //border: 1px solid red;
	}

	&__name-wrap {
		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: left;
		padding-left: 30px;
		//border: 1px solid blue;
	}
	
	&__user-avatar {
		height: 40px;
	  width: 40px;
	  border-radius: 50%;
	  margin-right: 20px;
	}

	&__user-name {
		padding-top: 12px;
		width: 212px;
		//border: 1px solid blue;
	}

	&__user-position {
		width: 20%;
		text-align: left;
		color: rgba(163,162,163,255);
		//border: 1px solid red;
	}

	&__user-status {
		width: 34%;
		text-align: center;
		padding-top: 10px;
		//border: 1px solid red;
	}
	&__status-picked-label {
		width: 210px;
		height: 30px;
		border-radius: 15px;
		color: rgba(0,202,73,255);
		background-color:rgba(219,248,228,255);
		margin-right: 20px;

		//border: 1px solid blue;
	}
	&__status-denied-label {
		width: 210px;
		height: 30px;
		border-radius: 15px;
		color: rgba(255,76,87,255);
		background-color: rgba(255,226,225,255);
		margin-right: 20px;
		//border: 1px solid blue;
	}

	&__button-wrap {
		margin-right: 10px;
		//border: 1px solid green;
	}

	&__deny-button {
		width: 100px;
		height: 30px;
		border-radius: 15px;
		color: rgba(253,108,107,255);
		background-color: white;
		border: 1px solid rgba(253,108,107,255);
		margin-right: 10px;
		cursor: pointer;

		&:hover {
			color: white;
			background-color: rgba(253,108,107,255);
		}
	}
	&__approve-button {
		width: 100px;
		height: 30px;
		border-radius: 15px;
		color: rgba(72,177,255,255);
		background-color: white;
		border: 1px solid rgba(72,177,255,255);
		cursor: pointer;

		&:hover {
			color: white;
			background-color: rgba(72,177,255,255);
		}
	}

	&__approve-button-disabled {
		width: 100px;
		height: 30px;
		border-radius: 15px;
		color: rgba(119,124,130,255);
		background-color: rgba(242,242,248,255);
		//border: 1px solid rgba(72,177,255,255);
		//cursor: pointer;
		/*
		&:hover {
			color: white;
			background-color: rgba(72,177,255,255);
		}
		*/
	}

	&__cancel-button {
		width: 100px;
		height: 30px;
		border-radius: 15px;
		color: rgba(116,122,128,255);
		background-color: white;
		border: 1px solid rgba(116,122,128,255);
		margin-right: 10px;
		cursor: pointer;

		&:hover {
			color: white;
			background-color: black;
		}
	}

	&__confirm-button {
		width: 100px;
		height: 30px;
		border-radius: 15px;
		color: rgba(116,122,128,255);
		background-color: white;
		border: 1px solid rgba(116,122,128,255);
		cursor: pointer;

		&:hover {
			color: white;
			background-color: black;
		}
	}

}