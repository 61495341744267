@font-face {
  font-family: 'Trueno';
  font-weight: 100;

  src: url('/fonts/trueno/TruenoUltLt.otf');
}

@font-face {
  font-family: 'Trueno';
  font-weight: 300;

  src: url('/fonts/trueno/TruenoLt.otf');
}

@font-face {
  font-family: 'Trueno';
  font-weight: 500;

  src: url('/fonts/trueno/TruenoRg.otf');
}

@font-face {
  font-family: 'Trueno';
  font-weight: 600;

  src: url('/fonts/trueno/TruenoSBd.otf');
}

@font-face {
  font-family: 'Trueno';
  font-weight: 700;

  src: url('/fonts/trueno/TruenoBd.otf');
}

@font-face {
  font-family: 'Trueno';
  font-weight: 800;

  src: url('/fonts/trueno/TruenoBlk.otf');
}

@font-face {
  font-family: 'Trueno';
  font-weight: 900;

  src: url('/fonts/trueno/TruenoExBd.otf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 100;

  src: url('/fonts/sf-pro/SF-Pro-Display-Ultralight.otf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 200;

  src: url('/fonts/sf-pro/SF-Pro-Display-Thin.otf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 300;

  src: url('/fonts/sf-pro/SF-Pro-Display-Regular.otf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 500;

  src: url('/fonts/sf-pro/SF-Pro-Display-Medium.otf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 600;

  src: url('/fonts/sf-pro/SF-Pro-Display-Semibold.otf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 700;

  src: url('/fonts/sf-pro/SF-Pro-Display-Bd.otf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 800;

  src: url('/fonts/sf-pro/SF-Pro-Display-Heavy.otf');
}

@font-face {
  font-family: 'SF Pro Display';
  font-weight: 900;

  src: url('/fonts/sf-pro/SF-Pro-Display-Black.otf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 100;

  src: url('/fonts/sf-pro/SF-Pro-Text-Light.otf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 200;

  src: url('/fonts/sf-pro/SF-Pro-Text-Thin.otf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 300;

  src: url('/fonts/sf-pro/SF-Pro-Text-Regular.otf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 500;

  src: url('/fonts/sf-pro/SF-Pro-Text-Medium.otf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 600;

  src: url('/fonts/sf-pro/SF-Pro-Text-Semibold.otf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 700;

  src: url('/fonts/sf-pro/SF-Pro-Text-Bold.otf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 800;

  src: url('/fonts/sf-pro/SF-Pro-Text-Heavy.otf');
}

@font-face {
  font-family: 'SF Pro Text';
  font-weight: 900;

  src: url('/fonts/sf-pro/SF-Pro-Text-Black.otf');
}

@font-face {
  font-family: 'AvertaBold';
  font-weight: 900;

  src: url('/fonts/averta/Averta-Bold.ttf');
}

@font-face {
  font-family: 'AvertaExtraBoldItalic';
  font-weight: 900;

  src: url('/fonts/averta/Averta-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'AvertaLight';
  font-weight: 200;

  src: url('/fonts/averta/Averta-Light.ttf');
}

@font-face {
  font-family: 'AvertaRegular';
  font-weight: 200;

  src: url('/fonts/averta/Averta-Regular.ttf');
}

@font-face {
  font-family: 'AvertaSemibold';
  font-weight: 500;

  src: url('/fonts/averta/Averta-Semibold.ttf');
}
