@import 'stylesheets/_vars.scss';

.login__footer {
  margin-top: 30px;
}

.login__form-error-text {
  max-width: 500px;
  min-width: 320px;
  width: 100%;
  min-height: 20px;
}

form.login__form {
  margin-top: 100px;
  max-width: 420px;
  margin: auto;
}

.login__wrap {
  p {
    text-align: center;
  }
}

.login {
  & > p {
    text-align: center;
  }
  padding-top: 50px;
  padding-bottom: 25px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
