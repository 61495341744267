@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

$dark-color: #2a2a2a;
$modal-border-radius: 8px;

.loading {
  position: relative;
  top: 50px;
  margin: auto;
}

.general-modal {
  max-width: 720px;
  display: block;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 80px;
  text-align: center;
  background-color: white;
  border-bottom-right-radius: $modal-border-radius;
  border-bottom-left-radius: $modal-border-radius;

  &__header {
    border-top-right-radius: $modal-border-radius;
    border-top-left-radius: $modal-border-radius;

    &-text {
      margin-top: 0;
      font-weight: 500;
      color: black;
      font-size: 24px;
      line-height: 65px;
    }
  }

  &__body {
    background-color: white;
    // height: 200px;
    min-height: 150px;
    padding: 15px 25px;
    min-width: 600px;
    text-align: center;
  }

  &__description {
    text-align: center;
    color: $headerColor;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 200;

    &:last-child {
      color: $dark-color;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  &__action-section {
    padding: 15px 30px 25px 30px;
    // height: 30px;
    // height: 60px;
    // margin-bottom: 60px;
    text-align: center;
  }

  &__action-button {
    margin: 0 0.5rem !important;

    &.button.button-primary {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 200;
      font-size: 14px;
      padding: 10px 30px;
      line-height: 30px;
      transition: 250ms all ease-out;

      // max-width: 200px;

      &:hover {
        text-decoration: none;
        @include buttonBoxShadow($primary-blue);
        background-color: $primary-blue;
        color: white;
      }

      &__inverse {
        transition: 250ms all ease-out;
        text-transform: uppercase;
        font-weight: 200;
        padding: 15px 50px;
        line-height: 30px;
        max-width: 175px;
        background-color: white;
        border: 1px solid $primary-blue;
        color: $primary-blue;

        &:hover {
          text-decoration: none;
          @include buttonBoxShadow($primary-blue);
          background-color: white;
        }
      }
    }
  }

  &__logo {
    width: 120px;
    height: 120px;
    padding-top: 10px;
  }
}
