@import 'stylesheets/vars';

.general-data--container {
  display: block;
  margin-top: 75px;
  max-width: 1170px;
  width: 100%;
  background-color: white;
  min-height: 250px;
  margin: auto;
  border-radius: 5px;
  text-align: center;

  &--request-location {
    background-color: transparent;
  }
}

.information-link {
  font-size: 20px;
}

.information-link {
  float: right;
  font-size: 12px;
  margin-left: 10px;
  width: 25%;
  overflow: hidden;
  
  & > svg {
    height: 12px;
    color: $neutralGreyColor;
    fill: $neutralGreyColor;
  }
}

.general-data--loader {
  position: absolute;
  width: 100%;
  height: 320px;
  max-width: 1140px;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.9);

  & img {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
  }

  &.show {
    z-index: 3;
  }
}

.general-data--header {
  color: $subtextColor;
  width: 50%;
  margin: auto;
  z-index: 4;
  text-align: center;
  padding-top: 20px;
}

.comparison-counter {
  margin-top: 30px;
  width: 24.5%;
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.comparison-counter:not(:last-child) {
  border-right: 1px solid $neutralGreyColor;
}
