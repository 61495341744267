@import 'stylesheets/bootstrap/_bootstrap-required.scss';
@import 'stylesheets/_mixins.scss';

.existing-accounts {
  &__container {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    margin: auto;
    @include make-container();

    & .form-actions {
      margin-bottom: 35px;

      & .signup-button {
        margin-right: 15px;
      }
    }
  }

  &__header {
    margin-top: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  &__sub-header {
    margin-top: 20px;
    margin-bottom: 40px;
  }

  &.form-actions {
    &:first-child {
      margin-right: 15px;
      margin-bottom: 35px;
    }
  }
}
