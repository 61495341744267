@import 'stylesheets/_vars.scss';

p.login__form-errors {
  margin-top: 0;
  &--primary-red {
    color: $primary-red;
  }
}

.confirmation-description {
  text-align: center;
}
