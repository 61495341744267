@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';


$scheduledShiftColor: #35373A;
$openShiftColor: #006FFB;
$vtoColor: #FFC043;
$teamShiftColor: #09A258;
$regionalShiftColor: #F037A5;



.request-card {
  &__statusbar-open-shift {
    display: flex;
    flex-direction: column;
    //border-left: 5px solid rgba(0,134,255,255);
    border-left: 5px solid $openShiftColor;
    border-radius: 4px;
  }

  &__statusbar-team-shift {
    display: flex;
    flex-direction: column;
    //border-left: 5px solid rgba(0,134,255,255);
    border-left: 5px solid $teamShiftColor;
    border-radius: 4px;
  }

  &__statusbar-vto {
    display: flex;
    flex-direction: column;
    //border-left: 5px solid rgba(0,134,255,255);
    border-left: 5px solid $vtoColor;
    border-radius: 4px;
  }

  &__statusbar-scheduled-shift {
    display: flex;
    flex-direction: column;
    //border-left: 5px solid rgba(0,134,255,255);
    border-left: 5px solid $scheduledShiftColor;
    border-radius: 4px;
  }

  &__statusbar-regional-shift {
    display: flex;
    flex-direction: column;
    //border-left: 5px solid rgba(0,134,255,255);
    border-left: 5px solid $regionalShiftColor;
    border-radius: 4px;
  }

  &__title-name-date {
    display: flex;
    flex-direction: row;
  }

  &__title {
    margin-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    //border: 1px solid blue;
  }
  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    //border: 1px solid blue;
  }
  &__date {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    //border: 1px solid blue;
  }

  &__job-date-wrap {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    margin-top: 1px;
    padding-left: 5px;
    //border: 1px solid blue;
  }

  &__time-wrap {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 25px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 1px;
    padding-left: 5px;
    //border: 1px solid blue;
  }

  &__second-row {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    //border: 1px solid red;
  }

  &__applicants {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
    //border: 1px solid blue;
  }

  &__avatars-wrap {
    width: 75px;
    height: 35px;
    position: relative;
    margin-right: 1rem;
    margin-top: 14px;

    //border: 1px solid red;
  }

  &__thumb-wrap {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 36px;
    height: 36px;
    z-index: 2;
    background-color: white;
    border-radius: 50%;

    //border: 1px solid red;

    &:not(:first-child) {
      left: 15px;
      overflow: hidden;
      z-index: 1;
    }

    &:last-child {
      left: 30px;
      overflow: hidden;
      z-index: 0;
    }
    //border: 1px solid blue;
  }

   &__avatars-wrap-single {
    width: 35px;
    height: 35px;
    position: relative;
    margin-right: 15px;
    margin-top: 14px;
    //border: 1px solid red;
  }

  &__thumb-wrap-single {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 36px;
    height: 36px;
    z-index: 2;
    background-color: white;
    border-radius: 50%;
    //border: 1px solid red;
  }

  &__avatar {
    width: 34px;
    height:34px;
    border-radius: 50%;
  }

  &__verified-badge {
    position: absolute;
    top: 18px;
    left: 22px;
    width: 20px;
    height: 20px;
    z-index: 2;
  }
}

.colored-circle {
  display: inline-block;
  height: 5px;
  width: 5px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
  border: 3px solid black;
}
.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.05); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  border: 1px solid red;
}

