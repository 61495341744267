@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.upload {
  padding-top: 50px;
  //padding-bottom: 25px;
  overflow: auto;
  //rgba(121,115,105,255)
  //border: 1px solid red;

  &__wrap {
    @extend %inner-wrap;
    padding-top: 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 680px) {
    padding-top: 0;
    margin-top: 50px;
  }

  &__header-wrap {
    margin-bottom: 0px;
    //border: 1px solid red;
  }

  &__step-1 {
    margin-top: 20px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: rgba(9,9,17,255);
    //border: 1px solid red;
  }
  &__main-body {
    width: 100%;
    max-width: 800px;
    height: 330px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed rgba(155,151,144,255);
  }
  &__drag-drop {
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: rgba(9,9,17,255);
    margin-top: 5px;
  }
  &__drag-drop-image {
    witdh: 60px;
    height: 70px;
  }
  &__download {
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: rgba(106,112,120,255);
  }
  &__supported {
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 10px;
    font-weight: 600;
    text-align: left;
    color: rgba(106,112,120,255);
  }
  &__file-input {
    display: none;
    border-radius: 6px;
    //margin-top: 0;
    //margin-left: 200px;
    //margin-bottom: 0.6rem;
    width: 615px;
    max-width: 615px;
    height: 28px;
    position: relative;
    order: 1;

    @media (max-width: 420px) {
      min-width: 200px;
    }
  }
  &__button-input {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    width: 95px;
    height: 40px;
    color: white;
    background-color: rgba(0,135,255,255);
    border-radius: 10px;
    //border: 1px solid red;

    &:hover {
      /*
      color: blue;
      background-color: white;
      border: 1px solid blue;
      */
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 6px
        white;
    }
  }
  &__button-submit {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    width: 95px;
    height: 40px;
    color: white;
    background-color: rgba(0,135,255,255);
    border-radius: 10px;
    //border: 1px solid red;

    &:hover {
      /*
      color: blue;
      background-color: white;
      border: 1px solid blue;
      */
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 6px
        white;
    }
  }
   &__button-submit-disabled {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    width: 95px;
    height: 40px;
    color: rgba(101,108,115,255);
    background-color: rgba(242,242,247,255);
    border-radius: 10px;
    //border: 1px solid red;

    &:hover {
      /*
      color: blue;
      background-color: white;
      border: 1px solid blue;
      */
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 6px
        white;
    }
  }
  &__step-2 {
    margin-top: 46px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: rgba(9,9,17,255);
    //border: 1px solid red;
  }
  &__wrong-file-type {
    color: red;
  }
  &__file-link-wrap {
    margin-top: 10px;
    //border: 1px solid red;
  }
  &__file-link-image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  &__file-link-text {
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 14px;
    font-weight: 600;
    color: rgb(44, 44, 46);
  }
}