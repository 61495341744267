.ip-whitelist-page {
  text-align: center;
  // max-width: 720px;

  &__icon {
    margin-bottom: 0.5rem;
  }

  &__button-wrap {
    margin-top: 2rem;
  }

  &__status-text {
    max-width: 610px;
    margin: 0 auto;
  }
}
