@import './bootstrap/bootstrap-custom';
@import './vars';
@import './fonts';
@import '../components/PageHeader/PageHeader.scss';
@import './components/Buttons.scss';
@import './components/Links.scss';
@import './components/Inputs.scss';
@import './components/Headers.scss';

* {
  font-family: $fontFamily;
}

html {
  position: relative;

  overflow-x: hidden;

  height: 100%;

  // background-color: $bodyBackgroundColor;
}

#root {
  // min-height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
