@import '../mixins.scss';

.user-dropdown--button {
  // @include thumbnail;
  position: relative;
  margin-left: 30px;
  margin-right: 60px;
  padding: 0;
  border: none;
  background-color: transparent;
  width: 44px;
  height: 28px;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  & img {
    @include thumbnail;
  }
}

.user-dropdown--list {
  position: relative;
  display: none;
  background-color: #2a2a2a;
  border-radius: 5px;
  margin-top: 3px;
  list-style: none;
  padding: 10px;

  &.open {
    display: block;
  }
}

.user-dropdown--list-arrow {
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  top: -7px;
  border-width: 0 7px 7px 7px;
  border-color: #2a2a2a;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  border-style: solid;
}

.user-dropdown--list-element {
  box-sizing: border-box;
  padding: 5px 5px;
  width: 100%;
  font-weight: 200;
  cursor: pointer;
  color: white;

  &:hover {
    color: #64d5ff;
  }

  & .active {
    color: #64d5ff;
  }

  & > a.user-dropdown--link {
    color: white;

    &:hover {
      color: #64d5ff;
    }

    & .active {
      color: #64d5ff;
    }
  }
}
