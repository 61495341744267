@import '../vars.scss';

a {
  text-decoration: none;
  color: black;
}

.link {
  text-decoration: none;
  color: $linkColor;

  transition: 250ms ease color;

  &:hover {
    color: darken($linkColor, 20%);
  }
}
