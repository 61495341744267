.pending-location__container {
  display: block;
  margin: auto;
  margin-top: 50px;
  text-align: center;

  & h1 {
    font-size: 24px;
  }

  & p {
    font-size: 14px;
  }
}
