.shift-request--outcome {
  margin: 0;
  padding: 0;
}

.shift-request--managed {
  .shift-request--description-text {
    width: 100%;
  }
}
