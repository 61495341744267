@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.shift-page-header {
  

  &__top-wrap {
    //@extend %inner-wrap;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    padding-top: 0;
    background-color: white;
    //border-bottom: 1px solid rgba(208,208,212,255);
    //border: 1px solid blue;

    @media (max-width: 880px) {
      padding-top: 0;
      margin-top: 50px;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width:100%;
  }

  &__row-1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
    //border: 1px solid red;
  }

  &__schedule-text {
    margin-top: 6px;
    margin-left: 20px;
    @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
    }
    font-family: 'AvertaRegular';
    font-size: 15px;
    font-weight: 400;
    //border: 1px solid red;
  }

  &__schedule-arrow-button {
    width: 15px;
    height: 15px;
    margin-left: 15px;
    margin-top: 10px;
    background-image: url("/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //border: 1px solid red;
  }

  &__results-text {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 400;
    //border: 1px solid green;
  }

  &__row-2 {
    width: 100%;
    height: 40px;
    position: relative;
    display: flex;
    flex-direction: row;
    //border: 1px solid red;
  }
  &__search-box {
    width: 25%;
    height: 38px;
    margin-left: 15px;
    padding-left: 60px;
    border: 1px solid rgba(115,124,132,255);
    border-radius: 7px;
    background-image: url("../../assets/search-icon.svg");
    background-repeat: no-repeat;
    background-position: 20px 10px;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 15px;
    font-weight: 600;
  }

  &__import-id {
    height: 38px;
    width: 160px;
    margin-left: 15px;
    border: 1px solid rgba(115,124,132,255);
    border-radius: 19px;
    background-image: url("../../assets/close-icon.svg");
    background-repeat: no-repeat;
    background-position: 135px 10px;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    padding-top: 10px;
    padding-left: 10px;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__import-id-value {
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    //border: 1px solid red;
  }

  &__add-filters {
    height: 38px;
    width: 80px;
    margin-left: 15px;
    border: 1px solid rgba(115,124,132,255);
    border-radius: 7px;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__hide-filters {
    height: 18px;
    margin-left: 15px;
    margin-top: 10px;
    border-bottom: 1px solid rgba(115,124,132,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__clear-all {
    height: 18px;
    margin-left: 15px;
    margin-top: 10px;
    border-bottom: 1px solid rgba(115,124,132,255);
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__sort-drop-down {
    position: absolute;
    top: 0px;
    right: 130px;
    width: 180px;
    height: 38px;
    padding-top: 7px;
    padding-left: 15px;
    border-radius: 6px;
    border: 1px solid rgba(115,124,132,255);
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
  }
  &__date-text {
    margin-left: 10px;
    margin-top: 1px;
    padding-top: 0;
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 15px;
    font-weight: 600;
    //border: 1px solid red;
  }
  &__arrow-button {
    width: 15px;
    height: 15px;
    margin-left: 40px;
    margin-top: 4px;
    background-image: url("/icons/down-arrow.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    //border: 1px solid red;
  }
  &__sort-button {
    position: absolute;
    top: 0px;
    right: 75px;
    width: 38px;
    height: 38px;
    border-radius: 6px;
    border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/descending-arrow-up.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border: 1px solid rgba(0,135,255,255);
    }
  }
  &__refresh-button {
    position: absolute;
    top: 0px;
    right: 20px;
    width: 38px;
    height: 38px;
    border-radius: 6px;
    border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/refresh-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border: 1px solid rgba(0,135,255,255);
    }
  }
}