@import 'stylesheets/_vars.scss';

.upper-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.week-picker {
  margin-right: 1.5rem;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  text-align: center;
}

.week-picker--current-date-range {
  font-size: 18px;
  width: 188px;
  float: left;
  word-wrap: break-word;
  font-weight: 600;
  color: $darkFontColor;
}

.week-picker--button {
  background: none;
  border: none;
  cursor: pointer;
}
