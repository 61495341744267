@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.summary {
  

  &__wrap {
    width: 100%;
    height: 89px;
    display: flex;
    flex-direction: row;
    padding-top: 0;
    background-color: white;
    border-bottom: 1px solid rgba(208,208,212,255);
    overflow: auto;
    //border: 1px solid blue;

    @media (max-width: 880px) {
      padding-top: 0;
      margin-top: 50px;
    }
  }

  &__param-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    margin-top: 0;
    margin-left: 20px;
    margin-right: 20px;
    //border: 1px solid red;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

   &__param-wrap-selected {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    margin-top: 0;
    margin-left: 20px;
    margin-right: 20px;
    color: rgba(0,135,255,255);
    //border: 1px solid blue;

    &:hover {
      cursor: pointer;
      //color: rgba(0,135,255,255);
      //background-color: white;
      //border: 1px solid rgba(0,135,255,255);
    }
  }

  &__param-name {
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
    white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
    //border: 1px solid blue;
  }

  &__param-value {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
  }

   &__param-value-location {
    @font-face {
    font-family: 'AvertaBold';
    src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
    color: red; //rgb(142, 142, 147);
  }
  
}