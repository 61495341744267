@import 'stylesheets/_vars.scss';
@import 'stylesheets/_mixins.scss';

.title {
  
  &__wrap {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    padding-top: 0;
    background-color: white;
    border-bottom: 1px solid rgba(208,208,212,255);
    position: relative;

    @media (max-width: 880px) {
      padding-top: 0;
      margin-top: 50px;
    }
    //border: 1px solid blue;
  }

  &__text {
    height: 50px;
    padding-top: 10px;
    padding-left: 20px;
    margin-bottom: 0;
    @font-face {
    font-family: 'AvertaLight';
    src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 15px;
    font-weight: 500;
    color: rgba(117,117,117,255);
    //border-bottom: 1px solid rgba(208,208,212,255);
    //border: 1px solid blue;
  }

  &__button-group {
    display: flex;
    flex-direction: row;
    height: 40px;
    position: absolute;
    top: 4px;
    right: 20px;
    gap: 10px;
    //border: 1px solid red;
  }

  &__search-button {
    width: 40px;
    height: 40px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/search-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border-radius: 3px;
      border: 1px solid rgba(115,124,132,255);
      
    }

    &:focus {
      outline: none;
    }
  }

  &__question-button {
    width: 40px;
    height: 40px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/question-mark.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border-radius: 3px;
      border: 1px solid rgba(115,124,132,255);
      
    }

    &:focus {
      outline: none;
    }
  }

  &__plus-button {
    width: 40px;
    height: 40px;
    //border-radius: 6px;
    //border: 1px solid rgba(115,124,132,255);
    background-image: url("../../assets/plus-button-icon.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      color: rgba(0,135,255,255);
      background-color: white;
      border-radius: 3px;
      border: 1px solid rgba(115,124,132,255);
      
    }

    &:focus {
      outline: none;
    }
  }

  
}