$checkboxCheckedColor: #26cbb8;

%checkmark_shrunk {
  -ms-transform: scale(0.1, 0.1); /* IE 9 */
    -webkit-transform: scale(0.1, 0.1); /* Chrome, Safari, Opera */
    transform: scale(0.1, 0.1);

  transition: transform 150ms ease-in;
}

%checkmark_default {
  -ms-transform: scale(1, 1); /* IE 9 */
    -webkit-transform: scale(1, 1); /* Chrome, Safari, Opera */
    transform: scale(1, 1);
}

.checkbox-group {
  float: none;
  border: none;
  display: inline-block;
}

.checkbox-group--list-item {
  display: inline-block;
}

.checkbox-group--list-item--label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  margin-right: 15px;
  margin-bottom: 10px;
  line-height: 18px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 250ms ease-in;

  &:hover input + .checkbox-group--list-item--checkbox {
    background-color: #ccc;
  }

  & input:checked + .checkbox-group--list-item--checkbox {
    background-color: $checkboxCheckedColor;
    display: inline-block;

    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);

    .checkmark_circle {
      position: absolute;
      width: 18px;
      height: 18px;
      background-color: transparent;
      border-radius: 11px;
      left: 0;
      top: 0;

      @extend %checkmark_default;
    }

    .checkmark_stem {
      position: absolute;
      width: 3px;
      height: 9px;
      background-color: #fff;
      left: 8px;
      top: 4px;

      @extend %checkmark_default;
    }

    .checkmark_kick {
      position: absolute;
      width: 3px;
      height: 3px;
      background-color: #fff;
      left: 6px;
      top: 10px;

      @extend %checkmark_default;
    }
  }

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    transition: opacity 250ms ease-in;
  }
}

.checkmark_circle {
  @extend %checkmark_shrunk;
}

.checkmark_stem {
  @extend %checkmark_shrunk;
}

.checkmark_kick {
  @extend %checkmark_shrunk;
}

.checkbox-group--list-item--checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: lightgray;

  -ms-transform: rotate(225deg); /* IE 9 */
    -webkit-transform: rotate(225deg); /* Chrome, Safari, Opera */
    transform: rotate(225deg);

  transition: background-color 250ms ease-in, transform 250ms ease-in;
}
