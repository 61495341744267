@import 'stylesheets/vars';
@import 'stylesheets/mixins';

$text-color: #90959A;

.date-time-button {
  @extend %button-reset;
  display: flex;
  border: $border;
  font-size: 14px;
  color: $text-color;

  &__period-btn-section {
    font-family: $sf-pro;
    font-weight: 300;
    min-width: 106px;
    text-align: left;
    padding: 0.5rem 0.66rem;

    .icon-calendar,
    .icon-clock {
      font-size: 12px;
      color: $border-color;
    }
  }

  &__date-text {
    font-family: $sf-pro;
    font-weight: 300;
    margin-right: 0.2rem;
  }
}
