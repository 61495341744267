@import 'stylesheets/_vars.scss';
@import 'stylesheets/components/UserDropdown.scss';
/*
.page-header {
  text-align: center;

  .select-dropdown {
    @media (max-width: $shifts-sm) {
      display: none;
    }
  }
}

.page-header--links {
  list-style: none;
  display: inline-block;
  padding-left: 0;

  li {
    display: inline-block;
  }
}

.logo-container {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 16px;
  height: 50px;
  //border: 1px solid yellow;

  & img {
    //width: 153px;
    //height: 46.44px;
    width: 99px;
    height: 30px;
    margin-top: 20px;
    margin-left: 21px;
    //border: 1px solid red;
  }
}

.logo-only-container {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 16px;
  height: 50px;
  //border: 1px solid yellow;

  & img {
    width: 45px;
    height: 30px;
    margin-top: 20px;
    margin-left: 8px;
    //border: 1px solid red;
  }
}

.user-dropdown--container {
  position: absolute;
  right: 15px;
  top: 28px;
  z-index: 3;
}

.page-header--lower-section {
  position: absolute;
  top: 70px;
  padding-left: 0;

  @media (max-width: $shifts-sm) {
    display: none;
  }

  & li {
    display: inline-block;
  }
}
*/

// used code from here
.header-panel {
  //width: 60px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: auto;
  }

  @media (max-width: $shifts-sm) {
    height: 78px;
  }

  //border: 1px solid yellow;
}

.button {
  &.btn-nav {
    height: 40px;
    //width: 172px;
    width: 100%;
    text-align: left;
    text-transform: none;
    @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    font-weight: 300;
    margin-left: 0px;
    margin-bottom: 30px;
    display: inline-block;
    box-sizing: border-box;
    padding-top: 10px;
    padding-left: 0px;
    transition: border-bottom ease 300ms;
    border-radius: 0;
    color: rgba(248,248,248,255);
    background: none;
    border-left: 3px solid transparent; // consistent horizontal position

    //border: 1px solid blue;

    &:focus {
      outline: none;
    }

    &.active-page {
      background: none;
      background-color: rgba(63,63,65,255);
      border-left: 3px solid rgba(253,253,253,255);
      //border: 1px solid red;
    }

    & .btn-nav--img {
      margin-left: 12px;
      //border: 1px solid yellow;
    }
    
    //border: 1px solid blue;
  }
}

.section {
  width: 46px;
  height: 100vh; //100% viewport height
  background-color: black;
  display: flex;
  flex-direction: column;
  //justify-content: center; //vertical
  align-items: center; //horizontal
  //border: 1px solid red;
}

.shyft-logo {
  width: 30px;
  height: 30px;
  margin-top: 30px;
  margin-bottom: 0;
  //justfy-content: center;
  //align-items: center;

  //border: 1px solid red;
}

.nav-bar-list {
  width: 100%;
  margin-top: 50px;
  margin-left: 0px;
  padding-left: 0px;
  display: flex;
  flex-flow: column;
  //justfy-content: left;
  list-style-type: none;
  align-items: left;
  //border: 1px solid red;
}

.button-list {
  width: 46px; // must same as width in section
  position: absolute;
  left: 0;
  bottom: 55px;
  margin-top: 10px;
  margin-left: 0px;
  padding-left: 0px;
  display: flex;
  flex-flow: column;
  align-items: center;
  //border: 1px solid red;
}
/*
.user-info-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 100px;
  height: 65px;
  width: 180px;

  border-bottom: 1px solid rgba(77,79,84,255);
}
.user-avatar {
  width: 35px;
  height: 35px;
  //margin-left: 7px;
  margin-left: 20px;
  margin-top: 5px;
  border-radius: 50%;
  //border: 1px solid blue;
}
.name-position {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  //border: 1px solid red;
}
.full-name {
  margin-top: 7px;
   @font-face {
      font-family: 'AvertaBold';
      src: url('/fonts/averta/Averta-Bold.ttf');
    }
    font-family: 'AvertaBold';
    font-size: 12px;
    color: white;
    line-height: 100%;
    //border: 1px solid yellow;
}
.position {
  @font-face {
      font-family: 'AvertaLight';
      src: url('/fonts/averta/Averta-Light.ttf');
    }
    font-family: 'AvertaLight';
    font-size: 12px;
    color: white;
    line-height: 100%;
    //border: 1px solid white;
}
.toggle-container {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 50px;
  cursor: pointer;
  //border: 1px solid red;
}
.toggle-img {
  width: 30px;
  height: 30px;
  margin-left: 20px;
}
.toggle-text {
 @font-face {
    font-family: 'AvertaRegular';
    src: url('/fonts/averta/Averta-Regular.ttf');
  }
  font-family: 'AvertaRegular';
  //font-size: 16px;
  font-size: 12px;
  font-weight: 300;
  color: white;
  padding-top: 8px;
  margin-left: 6px;
  //border: 1px solid red;
}
*/

.button-wrap {
  width: 100%;
  height: 40px;
  padding-left: 8px;
  border-left: 3px solid transparent;
}

.button-wrap-selected {
  width: 100%;
  height: 40px;
  padding-left: 8px;
  background: none;
  background-color: rgba(63,63,65,255);
  border-left: 3px solid rgba(253,253,253,255);
  //border-left: 3px solid white;
}

.button-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  //border: 1px solid yellow;
}

.settings-button-wrap {
  width: 100%;
  height: 40px;
  padding-left: 13px;
  border-left: 3px solid transparent;
}

.settings-button-wrap-selected {
  width: 100%;
  height: 40px;
  padding-left: 13px;
  background: none;
  background-color: rgba(63,63,65,255);
  border-left: 3px solid rgba(253,253,253,255);
  //border-left: 3px solid white;
}
.settings-button-image {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  //border: 1px solid yellow;
}

