@import 'stylesheets/_vars.scss';
@import 'stylesheets/components/UserDropdown.scss';

.page-header {
  text-align: center;

  .select-dropdown {
    @media (max-width: $shifts-sm) {
      display: none;
    }
  }
}

.page-header--links {
  list-style: none;
  display: inline-block;
  padding-left: 0;

  li {
    display: inline-block;
  }
}

.logo-container {
  display: inline-block;
  position: absolute;
  left: 18px;
  top: 16px;

  & img {
    width: 153px;
    height: 46.44px;
  }
}

.user-dropdown--container {
  position: absolute;
  right: 15px;
  top: 28px;
  z-index: 3;
}

.page-header--lower-section {
  position: absolute;
  top: 70px;
  padding-left: 0;

  @media (max-width: $shifts-sm) {
    display: none;
  }

  & li {
    display: inline-block;
  }
}

.header {
  height: 104px;
  padding: 10px;
  background-color: white;
  flex: 0 0 104px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: auto;
  }

  @media (max-width: $shifts-sm) {
    height: 78px;
  }
}

.btn {
  &.btn-nav {
    text-align: center;
    margin-left: 30px;
    display: inline-block;
    box-sizing: border-box;
    padding: 5px;
    min-width: 100px;
    transition: border-bottom ease 300ms;
    border-radius: 0;
    color: $fontColor;
    background: none;
    border-bottom: 3px solid transparent;

    &:focus {
      outline: none;
    }

    &.active-page {
      background: none;
      border-bottom: 3px solid $linkColor;
    }

    & .btn-nav--text {
      margin-left: 6px;
    }
  }
}
